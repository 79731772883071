@import './variables.scss';

.App {
  background-color: #e9ecef;

  .username {
    font-weight: bold;
    font-style: italic;
  }

  nav {
    max-height: $nav_bar_height;
  }

  > .container {
    min-height: calc(100vh - #{$nav_bar_height} - #{$footer_height});
  }

  link {
    color: #09d3ac;
  }

  .footer {
    position: relative;
    bottom: 0;
    /* Set the fixed height of the footer here */
    min-height: $footer_height;

    font-size: 0.9rem;
    font-style: italic;
  }
}
