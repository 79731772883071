.overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 100;
	opacity: 0.7;

	// for spinner size
	> div {
		width: 5rem;
		height: 5rem;
		z-index: 1;
		border-width: 0.5rem;
	}
}
