@import-normalize; /* bring in normalize.css styles */

/* rest of app styles */
@import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');

// for material-ui
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Comfortaa', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
